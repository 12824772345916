<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="alert"></a-icon>
          <span>通知策略</span>
        </a-space>
      </template>
      <span>事件通知策略包含名称、严重性、动作等配置。</span>
    </a-page-header>
    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :getTableDataFunc="getTableDataFunc"
        :columnDefaultSelected="columnDefaultSelected"
        :columnsAll="columnsAll"
        :toolsList="['setting', 'reload', 'search', 'leftTool']"
        :refresh="refresh"
        :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
        :table-operation-width="120"
      >
        <template #leftTool>
          <create-button
            @click="$refs.inputDrawer.show()"
          >
          </create-button>
        </template>
        <!-- 表格自定义列 -->
        <template #name="{ slotProps }">
          <a-tooltip placement="topLeft" :title="slotProps.text">
            <a @click="$refs.inputDrawer.show(slotProps.record.id)">
              {{ slotProps.text }}
            </a>
          </a-tooltip>
        </template>
        <template #severities="{ slotProps }">
          <alert-severity-tag
            v-for="severity in slotProps.text"
            :key="severity"
            :severity="severity"
          ></alert-severity-tag>
        </template>
        <template #active="{ slotProps }">
          <boolean-tag :value="slotProps.text"></boolean-tag>
        </template>
        <template #operations="{ slotProps }">
          {{ `${slotProps.record.operations.length} 条` }}
        </template>
        <template #operation="{ slotProps }">
          <delete-button
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>
    </a-card>

    <input-drawer ref="inputDrawer" @updated="confirm"></input-drawer>
  </div>
</template>

<script>
import {
  deleteEventNotificationStrategy,
  getEventNotificationStrategyList
} from '@/api/event-notification-strategy'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import CreateButton from '@/components/button/CreateButton'
import BooleanTag from '@/components/tag/BooleanTag'
import AlertSeverityTag from '@/components/tag/AlertSeverityTag'
import InputDrawer from './modules/InputDrawer.vue'

export default {
  name: 'EventNotificationStrategy',
  components: {
    AlertSeverityTag,
    BooleanTag,
    DeleteButton,
    CreateButton,
    InputDrawer,
    ListLayout
  },
  data () {
    return {
      getTableDataFunc: getEventNotificationStrategyList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'severities',
          title: '严重性',
          width: 224,
          scopedSlots: {
            customRender: 'severities'
          }
        },
        {
          dataIndex: 'operations',
          title: '动作配置',
          width: 100,
          align: 'center',
          scopedSlots: {
            customRender: 'operations'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        },
        {
          dataIndex: 'active',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'active'
          },
          filters: [
            {
              text: '启用',
              value: 'true'
            },
            {
              text: '停用',
              value: 'false'
            }
          ],
          filterMultiple: false
        }
      ],
      columnDefaultSelected: [
        'name',
        'severities',
        'operations',
        'created_at',
        'active'
      ],
      refresh: false,
      tableSelectData: []
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteEventNotificationStrategy(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      }
      this.refresh = !this.refresh
    }
  }
}
</script>
