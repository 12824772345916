var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"destroy-on-close":true,"title":_vm.detail.id ? _vm.detail.name : '通知策略创建',"visible":_vm.visible,"width":1000},on:{"close":_vm.closeDrawer}},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"wrapperCol":{ span: 20 }}},[_c('a-form-model-item',{attrs:{"label":"名称","prop":"name"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"严重性","prop":"severities"}},[_c('a-select',{attrs:{"mode":"multiple","options":_vm.options.severity},model:{value:(_vm.form.severities),callback:function ($$v) {_vm.$set(_vm.form, "severities", $$v)},expression:"form.severities"}})],1),_c('a-form-model-item',{attrs:{"label":"事件源","prop":"eventSources"}},[_c('event-source-select',{model:{value:(_vm.form.eventSources),callback:function ($$v) {_vm.$set(_vm.form, "eventSources", $$v)},expression:"form.eventSources"}})],1),_c('a-form-model-item',{attrs:{"label":"业务","prop":"services"}},[_c('service-multi-select',{model:{value:(_vm.form.services),callback:function ($$v) {_vm.$set(_vm.form, "services", $$v)},expression:"form.services"}})],1),_c('a-form-model-item',{attrs:{"label":"资源配置","prop":"conditions"}},[_vm._l((_vm.form.conditions),function(condition,index){return _c('div',{key:`condition_${index}`,staticStyle:{"display":"flex"}},[_c('a-form-model-item',{staticStyle:{"margin-right":"16px"},attrs:{"prop":`conditions[${index}].source_type`,"rules":[
            {
              message: '请选择资源类型',
              required: true,
              trigger: 'change'
            }
          ]}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"options":_vm.options.sourceType},model:{value:(condition.source_type),callback:function ($$v) {_vm.$set(condition, "source_type", $$v)},expression:"condition.source_type"}})],1),_c('a-form-model-item',{staticStyle:{"width":"160px"},attrs:{"prop":`conditions[${index}].source_target`,"rules":[
            {
              message: '请选择资源范围',
              required: true,
              trigger: 'change'
            }
          ]}},[_c('a-select',{attrs:{"options":_vm.options.sourceTarget},model:{value:(condition.source_target),callback:function ($$v) {_vm.$set(condition, "source_target", $$v)},expression:"condition.source_target"}})],1),(condition.source_type)?[(condition.source_target === 'group')?_c('a-form-model-item',{staticStyle:{"margin-left":"16px"},attrs:{"prop":`conditions[${index}].groups`,"rules":[
              {
                message:
                  '请选择' +
                  _vm.$t(`source_type.${condition.source_type}`) +
                  '群组',
                required: true,
                trigger: 'change'
              }
            ]}},[_c('group-select',{staticStyle:{"width":"400px"},attrs:{"source-type":condition.source_type},model:{value:(condition.groups),callback:function ($$v) {_vm.$set(condition, "groups", $$v)},expression:"condition.groups"}})],1):(condition.source_target === 'selected')?_c('a-form-model-item',{staticStyle:{"margin-left":"16px"},attrs:{"prop":`conditions[${index}].sources`,"rules":[
              {
                message:
                  '请选择' + _vm.$t(`source_type.${condition.source_type}`),
                required: true,
                trigger: 'change'
              }
            ]}},[_c('multi-source-select',{staticStyle:{"width":"400px"},attrs:{"source-type":condition.source_type},model:{value:(condition.sources),callback:function ($$v) {_vm.$set(condition, "sources", $$v)},expression:"condition.sources"}})],1):_vm._e()]:_vm._e(),_c('a-form-model-item',{staticStyle:{"margin-left":"16px"}},[_c('a-button',{attrs:{"icon":"minus-circle","type":"link"},on:{"click":function($event){return _vm.form.conditions.splice(index, 1)}}})],1)],2)}),_c('a-button',{attrs:{"block":true,"icon":"plus","type":"dashed"},on:{"click":function($event){return _vm.form.conditions.push({
            source_type: undefined,
            source_target: undefined,
            sources: undefined,
            groups: undefined
          })}}},[_vm._v(" 新增资源配置 ")])],2),_c('a-form-model-item',{attrs:{"label":"动作配置","prop":"operations"}},[_vm._l((_vm.form.operations),function(operation,index){return _c('div',{key:`operation_${index}`,staticStyle:{"display":"flex"}},[_c('a-form-model-item',{staticStyle:{"margin-right":"16px"},attrs:{"prop":`operations[${index}].notification_method`,"rules":[
            {
              message: '请选择通知方式',
              required: true,
              trigger: 'change'
            }
          ]}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"options":_vm.options.notificationMethod},model:{value:(operation.notification_method),callback:function ($$v) {_vm.$set(operation, "notification_method", $$v)},expression:"operation.notification_method"}})],1),_c('a-form-model-item',{attrs:{"prop":`operations[${index}].delay`}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"options":_vm.options.delay},model:{value:(operation.delay),callback:function ($$v) {_vm.$set(operation, "delay", $$v)},expression:"operation.delay"}})],1),_c('a-form-model-item',{staticStyle:{"margin-left":"16px"},attrs:{"prop":`operations[${index}].contacts`,"rules":[
            {
              message: '请选择联系人',
              required: true,
              trigger: 'change'
            }
          ]}},[_c('contact-select',{staticStyle:{"width":"400px"},model:{value:(operation.contacts),callback:function ($$v) {_vm.$set(operation, "contacts", $$v)},expression:"operation.contacts"}})],1),_c('a-form-model-item',{staticStyle:{"margin-left":"16px"}},[_c('a-button',{attrs:{"icon":"minus-circle","type":"link"},on:{"click":function($event){return _vm.form.operations.splice(index, 1)}}})],1)],1)}),_c('a-button',{attrs:{"block":true,"icon":"plus","type":"dashed"},on:{"click":function($event){return _vm.form.operations.push({
            notification_method: undefined,
            contacts: undefined
          })}}},[_vm._v(" 新增动作配置 ")])],2),_c('a-form-model-item',{attrs:{"label":"状态","prop":"active"}},[_c('a-switch',{model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 20, offset: 4 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.ok}},[_vm._v("确定")]),_c('a-button',{on:{"click":_vm.closeDrawer}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }