import request from '@/utils/request'

const urlPrefix = '/events/notification-strategies'

export function getEventNotificationStrategy (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateEventNotificationStrategy (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteEventNotificationStrategy (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getEventNotificationStrategyList (params) {
  return request.get(urlPrefix, { params })
}

export function createEventNotificationStrategy (data) {
  return request.post(urlPrefix, data)
}
